<template>
  <div class="child-container about">
    <div v-html="content"></div>
  </div>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      content: ''
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$http.get(`/editor/about`).then((res) => {
        this.content = res.data.content || ''
      });
    },
  }
};
</script>
